import styled from 'styled-components';
import { breakpoints } from 'styles/breakpoints.styles';

export const FooterContainer = styled.footer`
  display: flex;
  justify-content: center;
  padding: 0 4rem;
  color: var(--color-text-footer);
  background-color: var(--color-footer-bg);

  .footer__wrapper {
    flex: 1;
    max-width: var(--max-width);

    .footer__menu {
      display: flex;
      align-items: center;
      column-gap: 3rem;
      row-gap: 0.5rem;
      flex-wrap: wrap;
      padding: 3rem 0;
      border-bottom: 0.1rem solid #dfdfdf;

      li {
        a {
          b {
            font-weight: 600;
          }
        }
      }

      @media ${breakpoints.mobile} {
        display: flex;
        justify-content: center;
        column-gap: 2rem;
        padding: 3rem 0 1.5rem;
        font-size: 1.4rem;
      }
    }

    .footer__info {
      padding: 4rem 0;

      .footer__info__name {
        margin-bottom: 3rem;
        font-size: 1.8rem;
        font-weight: 800;
      }

      .footer__info__wrapper {
        display: flex;
        align-items: center;
        column-gap: 2rem;
        row-gap: 0.4rem;
        flex-wrap: wrap;
        max-width: 60rem;
        color: var(--color-text-footer-light);

        p {
          max-width: 100%;
          text-wrap: pretty;
        }

        /* p {
          display: flex;
          align-items: inherit;
          gap: inherit;

          &:not(:last-child) {
            &::after {
              content: '';
              display: block;
              height: 1.6rem;
              width: 0.1rem;
              background-color: var(--color-text-footer-light);
            }
          }
        } */
      }

      .footer__copyright {
        color: var(--color-text-footer-light);
        font-weight: 500;
      }

      @media ${breakpoints.mobile} {
        padding: 3rem 0;
        font-size: 1.4rem;

        .footer__info__name {
          margin-bottom: 0.5rem;
          font-size: 1.6rem;
        }
      }
    }
  }

  @media ${breakpoints.mobile} {
    padding: 0 2rem;
  }
`;
