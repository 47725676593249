import { Link } from 'react-router-dom';
import { FooterContainer } from './styles';

const Footer = () => {
  return (
    <FooterContainer>
      <div className='footer__wrapper'>
        <ul className='footer__menu'>
          <li>
            <Link to={'/company'}>회사소개</Link>
          </li>
          <li>
            <Link to={'/company/map'}>오시는 길</Link>
          </li>
          <li>
            <Link to={'/contact'}>고객문의</Link>
          </li>
          <li>
            <Link to={'/privacy'}>
              <b>개인정보처리방침</b>
            </Link>
          </li>
        </ul>

        <div className='footer__info'>
          <p className='footer__info__name'>{process.env.REACT_APP_COMPANY}</p>

          <div className='footer__info__wrapper'>
            <p>대표이사 {process.env.REACT_APP_CHIEF}</p>
            <p>주소 {process.env.REACT_APP_COMPANY_ADDRESS}</p>
            <p>사업자등록번호 {process.env.REACT_APP_COMPANY_CODE}</p>
            <p>TEL {process.env.REACT_APP_COMPANY_TEL}</p>
            <p>E-mail {process.env.REACT_APP_COMPANY_EMAIL}</p>
          </div>

          <p className='footer__copyright'>
            COPYRIGHT© 2024. {process.env.REACT_APP_COMPANY_NAME} All Right
            reserved.
          </p>
        </div>
      </div>
    </FooterContainer>
  );
};

export default Footer;
