import styled from 'styled-components';
import { breakpoints } from 'styles/breakpoints.styles';
import { Btn } from 'styles/button.styles';
import chevron from 'assets/icon/down-chevron.png';

export const ContactContainer = styled.div`
  --max-width: 114rem;

  width: 100%;
  max-width: var(--max-width);

  form {
    .contact__wrapper {
      &:not(:last-child) {
        margin-bottom: 5rem;
      }

      .contact__wrapper__head {
        width: 100%;
        padding: 1rem 0;
        border-bottom: 0.1rem solid var(--color-border);

        p {
          font-size: 3.8rem;
          font-weight: 500;
          word-break: keep-all;
          text-wrap: pretty;
        }
      }

      .contact__form__row {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        padding: 3rem 0;

        &:not(:last-of-type) {
          border-bottom: 0.1rem solid var(--color-border-light);
        }

        label {
          min-width: 15rem;
          font-size: 1.8rem;
          line-height: 5rem;

          &::after {
            content: '*';
            color: #ff6014;
            margin-left: 0.3rem;
          }
        }

        input,
        select,
        textarea {
          flex: 1;
          max-width: 62rem;
          padding: 1.5rem 2rem;
          font-weight: 300;
          background-color: var(--color-grey-bg);
          border-radius: 0.5rem;

          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
        }

        select {
          padding-right: 2.4rem;
          background: no-repeat calc(100% - 0.5rem) 50%/1.4rem url(${chevron})
            var(--color-grey-bg);
        }

        textarea {
          resize: none;
        }

        .contact__form__term {
          flex: 1;
          max-height: 30rem;
          width: 100%;
          padding: 3rem 2rem;
          color: var(--color-text-light);
          background-color: var(--color-grey-bg);
          font-size: 1.6rem;
          font-weight: 300;
          line-height: 1.75;
          border: 0.1rem solid var(--color-border);
          border-radius: 0.5rem;
          overflow: auto;
          white-space: pre-wrap;
        }
      }

      input[type='checkbox'] {
        & + label {
          display: inline-block;
          margin-left: 0.5rem;
        }
      }

      .contact__form__error {
        width: 100%;
        padding-top: 1rem;
        color: var(--color-negative);
        font-weight: 500;
      }
    }
  }

  @media ${breakpoints.mobile} {
    form {
      .contact__wrapper {
        &:not(:last-child) {
          margin-bottom: 3rem;
        }

        .contact__wrapper__head {
          p {
            font-size: 3rem;
          }
        }

        .contact__form__row {
          flex-direction: column;
          align-items: flex-start;
          gap: 1rem;
          padding: 2rem 0;

          label {
            font-size: 1.6rem;
            line-height: normal;
          }

          input,
          select,
          textarea {
            flex: auto;
            width: 100%;
            max-width: 100%;
            padding: 1.2rem 1rem;
          }

          .contact__form__term {
            padding: 1rem;
          }

          .contact__form__error {
            padding-top: 0;
          }
        }

        .contact__form__error {
          font-size: 1.4rem;
        }
      }
    }
  }
`;

export const ContactSubmit = styled(Btn).attrs({ type: 'submit' })`
  display: block;
  width: 100%;
  max-width: 32rem;
  padding: 1rem;
  margin: 0 auto;
  color: #fff;
  background-color: var(--color-primary);
  font-size: 1.8rem;
  font-weight: 600;
  border-radius: 0.8rem;

  &:disabled {
    opacity: 0.5;
  }
`;
